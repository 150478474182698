const dashboard = {
  id: "group-dashboard",
  title: "",
  type: "group",
  url: "/",
  children: [
    {
      id: "dados-gerais",
      title: "RELATÓRIOS",
      type: "item",
      url: "dados-gerais",
      breadcrumbs: false,
    },
    {
      id: "estatisticas",
      title: "ESTATÍSTICAS",
      type: "item",
      url: "estatisticas",
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
